// import { initLocalization as initFR } from '@web-solutions/face-reading';
// import { initLocalization as initMS } from '@web-solutions/manage-subscription';
import base from "@web-solutions/base-app/localization/en.json"

import Localization from "@web-solutions/module-localization"
import _merge from "lodash/merge"

import { SUPPORT_EMAIL } from "src/constants/general"

import en from "./en.json"
import fr from "./fr.json"
import es from "./es.json"
import pt from "./pt.json"
// import faceReadingEn from './face-reading-en.json';

const resources = {
  en: {
    translation: _merge({}, base, en),
  },
  fr: {
    translation: _merge({}, base, fr),
  },
  es: {
    translation: _merge({}, base, es),
  },
  pt: {
    translation: _merge({}, base, pt),
  },
}

export function initLocalization(lang: string, APP_NAME?: string) {
  Localization.init(resources, {appName: APP_NAME, supportEmail: SUPPORT_EMAIL}, {lng:lang})
  // initFR(faceReadingEn);
  // initMS();
}

