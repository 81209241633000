export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL

export const COLORS = {
  MAIN_TEXT: "#2D3240",
  GREEN: "#16786C",
  LIGHT_GREEN1: "#D9EAE8",
  LIGHT_GREEN2: "#F4FDFC",
  ORANGE: "#ee8f61",
  WHITE: "#ffffff",
}
