import {
  COMPLETE_STEP,
  STEP_BACK,
  UPDATE_STEP,
  SET_PURCHASED,
  SET_TOTAL_STEPS_COUNT,
} from './types';

export const initialState = {
  currentStepIndex: 0,
  dataKeysStack: [],
  data: {},
  completed: false,
  purchased: false,
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPLETE_STEP:
      let nextStep = state.currentStepIndex + 1;
      let completed = false;

      if (nextStep > state.totalStepsCount) {
        nextStep--;
      }

      completed = nextStep == state.totalStepsCount;

      return {
        ...state,
        currentStepIndex: nextStep,
        dataKeysStack: [...state.dataKeysStack, action.payload.key,],
        data: {
          ...state.data,
          [action.payload.key]: action.payload.data,
        },
        completed,
      };

    case UPDATE_STEP:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.key]: action.payload.data,
        },
      };

    case STEP_BACK:
      if (state.currentStepIndex == 0)
        return state;

      return {
        ...state,
        currentStepIndex: (state.currentStepIndex - 1),
        dataKeysStack: [...state.dataKeysStack,],
        data: { ...state.data, },
        completed: false,
      };

    case SET_PURCHASED:
      return {
        ...state,
        purchased: action.payload,
      }

    case SET_TOTAL_STEPS_COUNT:
      return {
        ...state,
        totalStepsCount: action.payload,
      }

    default:
      return state;
  }
};

export default reducer;
