export const ANSWER_QUESTION = 'CHILD_PROFILE/ANSWER_QUESTION';
export const GO_BACK = 'CHILD_PROFILE/GO_BACK';
export const NEXT_STEP = 'CHILD_PROFILE/NEXT_STEP';
export const COMPLETE_ONBOARDING_STEP = 'CHILD_PROFILE/COMPLETE_ONBOARDING_STEP';
export const START_ONBOARDING = 'CHILD_PROFILE/START_ONBOARDING';
export const COMPLETE_ONBOARDING = 'CHILD_PROFILE/COMPLETE_ONBOARDING';
export const SET_QUIZ_SCREEN_ORDER = 'CHILD_PROFILE/SET_QUIZ_SCREEN_ORDER';
export const SET_TOTAL_QUIZ_STEPS = 'CHILD_PROFILE/SET_TOTAL_QUIZ_STEPS';
export const SET_QUIZ_DATA = 'CHILD_PROFILE/SET_QUIZ_DATA';
export const ADD_QUIZ_DATA = 'CHILD_PROFILE/ADD_QUIZ_DATA';
export const UPDATE_QUIZ_DATA = 'CHILD_PROFILE/UPDATE_QUIZ_DATA';

export const SET_IS_LOADING_MESSAGE_GPT = 'CHILD_PROFILE/SET_IS_LOADING_MESSAGE_GPT';
export const SET_LANG = 'CHILD_PROFILE/SET_LANG';
export const SET_QUIZ_VARIANT = 'CHILD_PROFILE/SET_QUIZ_VARIANT';
export const SKIP_EBOOK_PURCHASE = 'CHILD_PROFILE/SKIP_EBOOK_PURCHASE';
export const CANCEL_SKIP_EBOOK_PURCHASE = 'CHILD_PROFILE/CANCEL_SKIP_EBOOK_PURCHASE';

export const GO_ONBOARDING_BACK = 'CHILD_PROFILE/GO_ONBOARDING_BACK';
export const SET_INITIAL_CHILD_PHOTO = 'CHILD_PROFILE/SET_INITIAL_CHILD_PHOTO';
export const SET_FINAL_CHILD_PHOTO = 'CHILD_PROFILE/SET_FINAL_CHILD_PHOTO';
export const SET_SELECT_PROBLEMS = 'CHILD_PROFILE/SET_SELECT_PROBLEMS';
export const SET_HIGHLIGHT_PLAN_ITEMS = 'CHILD_PROFILE/SET_HIGHLIGHT_PLAN_ITEMS';
export const SET_RESULTS_MAKE_US_PROUD_ITEMS = 'CHILD_PROFILE/SET_RESULTS_MAKE_US_PROUD_ITEMS';
export const SET_PEOPLE_OFTEN_ASK_ITEMS = 'CHILD_PROFILE/SET_PEOPLE_OFTEN_ASK_ITEMS';
export const SET_BECOME_MEMBER_ITEMS = 'CHILD_PROFILE/SET_BECOME_MEMBER_ITEMS';
export const SET_QUIZ_PROMISE_REQUEST = 'CHILD_PROFILE/SET_QUIZ_PROMISE_REQUEST';
export const SET_USER_INPUT_MESSAGE = 'CHILD_PROFILE/SET_USER_INPUT_MESSAGE';
export const SET_ALL_BLOCKS_PROMISE_REQUEST = 'CHILD_PROFILE/SET_ALL_BLOCKS_PROMISE_REQUEST';
export const SET_QUIZ_HEADER_TEXT = 'CHILD_PROFILE/SET_QUIZ_HEADER_TEXT';
export const SET_TWO_DAY_PLAN_ITEMS = 'CHILD_PROFILE/SET_TWO_DAY_PLAN_ITEMS';
export const SET_TWO_DAY_PLAN_REST_ITEMS = 'CHILD_PROFILE/SET_TWO_DAY_PLAN_REST_ITEMS';
export const SET_TWO_DAY_PLAN_PROMISE_REQUEST = 'CHILD_PROFILE/SET_TWO_DAY_PLAN_PROMISE_REQUEST';
export const SET_TWO_DAY_PLAN_RESULT_GPT_MESSAGE = 'CHILD_PROFILE/SET_TWO_DAY_PLAN_RESULT_GPT_MESSAGE';
export const UPDATE_ANSWER_COUNT = 'CHILD_PROFILE/UPDATE_ANSWER_COUNT';




