import {
  ADD_QUIZ_DATA,
  ANSWER_QUESTION,
  CANCEL_SKIP_EBOOK_PURCHASE,
  COMPLETE_ONBOARDING,
  COMPLETE_ONBOARDING_STEP,
  GO_BACK,
  GO_ONBOARDING_BACK,
  NEXT_STEP,
  SET_ALL_BLOCKS_PROMISE_REQUEST,
  SET_BECOME_MEMBER_ITEMS,
  SET_FINAL_CHILD_PHOTO,
  SET_HIGHLIGHT_PLAN_ITEMS,
  SET_INITIAL_CHILD_PHOTO,
  SET_IS_LOADING_MESSAGE_GPT,
  SET_LANG,
  SET_PEOPLE_OFTEN_ASK_ITEMS,
  SET_QUIZ_DATA,
  SET_QUIZ_HEADER_TEXT,
  SET_QUIZ_PROMISE_REQUEST,
  SET_QUIZ_SCREEN_ORDER,
  SET_QUIZ_VARIANT,
  SET_RESULTS_MAKE_US_PROUD_ITEMS,
  SET_SELECT_PROBLEMS,
  SET_TOTAL_QUIZ_STEPS,
  SET_TWO_DAY_PLAN_ITEMS,
  SET_TWO_DAY_PLAN_PROMISE_REQUEST,
  SET_TWO_DAY_PLAN_REST_ITEMS,
  SET_TWO_DAY_PLAN_RESULT_GPT_MESSAGE,
  SET_USER_INPUT_MESSAGE,
  SKIP_EBOOK_PURCHASE,
  START_ONBOARDING,
  UPDATE_ANSWER_COUNT,
  UPDATE_QUIZ_DATA,
} from './types';

export const initialState = {
  currentOnboardingStepIndex: 0,
  currentStepIndex: 0,
  skippedEbookPurchase: false,
  results: [],
  completedChildProfile: false,
  isOnboardingStarted: false,
  isOnboardingCompleted: false,
  totalQuizSteps: 0,
  quizData: [],
  lang: 'en',
  quizVariant: '',
  initialChildPhoto: '',
  finalChildPhoto: '',
  selectProblems: [],
  highlightPlanItems: [],
  resultsMakeUsProudItems: [],
  peopleOftenAskItems: [],
  becomeMemberItems: [],
  isLoadingFirstMessageGpt: false,
  isLoadingSecondaryMessageGpt: false,
  isLoadingTertiaryMessageGpt: false,
  isLoadingFourthMessageGpt: false,
  quizPromiseRequest: null,
  allBlockPromiseRequest: null,
  userInputMessage: null,
  quizHeaderText: '',
  quizBasedAnswerPlanHeaderText: '',
  twoDayPlanItems: [],
  twoDayPlanRestItems: [],
  twoDayPlanPromiseRequest: null,
  twoDayPlanResultGptMessage: null,
  correctAnswersCount: 0,
  incorrectAnswersCount: 0,
};

const childProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case ANSWER_QUESTION:
      const { currentStepIndex, totalQuizSteps, currentOnboardingStepIndex, results } = state;
      const { questionId, result, header } = action.payload;

      const updatedCurrentStepIndex = currentStepIndex + 1;
      const completed = updatedCurrentStepIndex === totalQuizSteps;

      if (completed) {
        return {
          ...state,
          currentStepIndex: updatedCurrentStepIndex,
          currentOnboardingStepIndex: currentOnboardingStepIndex + 1,
          results: [
            ...results,
            {
              questionId,
              result,
              header,
            },
          ],
          completedChildProfile: true,
        };
      } else {
        return {
          ...state,
          currentStepIndex: updatedCurrentStepIndex,
          results: [
            ...results,
            {
              questionId,
              result,
              header,
            },
          ],
        };
      }

    case UPDATE_QUIZ_DATA: {
      return {
        ...state,
        quizData: action.payload,
      };
    }

    case GO_BACK: {
      return {
        ...state,
        completedChildProfile: false,
        currentStepIndex: state.currentStepIndex - 1,
      };
    }

    case NEXT_STEP:
      return {
        ...state,
        currentStepIndex: state.currentStepIndex + 1,
      };

    case COMPLETE_ONBOARDING_STEP: {
      const { key, data } = action.payload;
      const nextStepIndex = state.currentOnboardingStepIndex + 1;
      return {
        ...state,
        currentOnboardingStepIndex: nextStepIndex,
        results: {
          ...state.results,
          [key]: data,
        },
      };
    }
    case START_ONBOARDING: {
      return {
        ...state,
        isOnboardingStarted: true,
      };
    }
    case COMPLETE_ONBOARDING: {
      return {
        ...state,
        isOnboardingCompleted: true,
      };
    }
    case SET_QUIZ_SCREEN_ORDER: {
      return {
        ...state,
        quizScreenOrder: action.payload,
      };
    }
    case SET_TOTAL_QUIZ_STEPS: {
      return {
        ...state,
        totalQuizSteps: action.payload,
      };
    }
    case SET_QUIZ_DATA: {
      return {
        ...state,
        quizData: action.payload,
      };
    }

    case ADD_QUIZ_DATA: {
      return {
        ...state,
        quizData: [...state.quizData, ...action.payload],
      };
    }

    case SET_LANG: {
      return {
        ...state,
        lang: action.payload,
      };
    }
    case SET_QUIZ_VARIANT:
      return {
        ...state,
        totalQuizSteps: action.payload,
        quizVariant: action.payload,
      };

    case CANCEL_SKIP_EBOOK_PURCHASE:
      return {
        ...state,
        skippedEbookPurchase: false,
      };
    case SKIP_EBOOK_PURCHASE:
      return {
        ...state,
        skippedEbookPurchase: true,
      };
    case GO_ONBOARDING_BACK:
      return {
        ...state,
        currentOnboardingStepIndex: state.currentOnboardingStepIndex - 1,
      };
    case SET_INITIAL_CHILD_PHOTO:
      return {
        ...state,
        initialChildPhoto: action.payload,
      };
    case SET_FINAL_CHILD_PHOTO:
      return {
        ...state,
        finalChildPhoto: action.payload,
      };

    case SET_SELECT_PROBLEMS:
      return {
        ...state,
        selectProblems: action.payload,
      };

    case SET_HIGHLIGHT_PLAN_ITEMS:
      return {
        ...state,
        highlightPlanItems: action.payload,
      };

    case SET_RESULTS_MAKE_US_PROUD_ITEMS:
      return {
        ...state,
        resultsMakeUsProudItems: action.payload,
      };

    case SET_PEOPLE_OFTEN_ASK_ITEMS:
      return {
        ...state,
        peopleOftenAskItems: action.payload,
      };

    case SET_BECOME_MEMBER_ITEMS:
      return {
        ...state,
        becomeMemberItems: action.payload,
      };

    case SET_IS_LOADING_MESSAGE_GPT: {
      const { key, isLoading } = action.payload;

      return {
        ...state,
        [key]: isLoading,
      };
    }
    case SET_QUIZ_PROMISE_REQUEST:
      return {
        ...state,
        quizPromiseRequest: action.payload,
      };
    case SET_ALL_BLOCKS_PROMISE_REQUEST:
      return {
        ...state,
        allBlockPromiseRequest: action.payload,
      };

    case SET_USER_INPUT_MESSAGE:
      return {
        ...state,
        userInputMessage: action.payload,
        // selectProblems: action.payload
        //   ? [...state.selectProblems, { label: action.payload, emoji: '💬' }]
        //   : [...state.selectProblems],
      };

    case SET_QUIZ_HEADER_TEXT:
      return {
        ...state,
        quizHeaderText: action.payload,
      };

    case SET_TWO_DAY_PLAN_ITEMS:
      return {
        ...state,
        twoDayPlanItems: action.payload,
      };

    case SET_TWO_DAY_PLAN_REST_ITEMS:
      return {
        ...state,
        twoDayPlanRestItems: action.payload,
      };

    case SET_TWO_DAY_PLAN_PROMISE_REQUEST:
      return {
        ...state,
        twoDayPlanPromiseRequest: action.payload,
      };

    case SET_TWO_DAY_PLAN_RESULT_GPT_MESSAGE:
      return {
        ...state,
        twoDayPlanResultGptMessage: action.payload,
      };

    case UPDATE_ANSWER_COUNT: {
      const { isCorrect } = action.payload;
      return {
        ...state,
        correctAnswersCount: isCorrect ? state.correctAnswersCount + 1 : state.correctAnswersCount,
        incorrectAnswersCount: !isCorrect
          ? state.incorrectAnswersCount + 1
          : state.incorrectAnswersCount,
      };
    }

    default:
      return state;
  }
};

childProfileReducer.blacklist = ['initialChildPhoto', 'finalChildPhoto'];

export default childProfileReducer;
