import * as TYPES from './types';

export const initialState = {
  authorized: false,
  apiAuth: null,
  email: '',
  gender: null,
  age: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_AUTHORIZED:
      return {
        ...state,
        authorized: true,
        apiAuth: action.payload,
      };

    case TYPES.SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };

    case TYPES.SET_AGE:
      return {
        ...state,
        age: action.payload,
      };

    case TYPES.SET_GENDER:
      return {
        ...state,
        gender: action.payload,
      };

    default:
      return state;
  }
};

reducer.blacklist = ['authorized'];

export default reducer;
